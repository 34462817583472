import React from 'react'

import { keyframes, css } from 'styled-components/macro'

const bounce = keyframes`
  0%, 100% {transform: scale(0)}
  50% {transform: scale(1.0)}
`

const fadein = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const Spin = ({ color = 'grey', size = 40 }) => {

  const wrapperStyle = css`
    opacity: 0;
    animation: 1s ease 0.3s normal forwards 1 ${fadein};
    position: relative;
    width: ${size}px;
    height: ${size}px;
  `

  const style = i => css`
    position: absolute;
    height: ${size}px;
    width: ${size}px;
    background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey300 ? props.theme.colors.grey300 : color};
    border-radius: 100%;
    opacity: 0.6;
    top: 0;
    left: 0;
    animation-fill-mode: both;
    animation: ${bounce} 2.1s ${i === 1 ? '1s' : '0s'} infinite ease-in-out;
  `

  return (
    <div css={wrapperStyle}>
      <div css={style(1)} />
      <div css={style(2)} />
    </div>
  )

}

export default Spin

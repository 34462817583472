import React from 'react'
import { useTransition, a } from 'react-spring'

import { Switch, Route, useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom'

function Lab() {

  const location = useLocation()
  console.log('location', location)

  const transition = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      <div>
        <Link to='/lab/a'>A</Link>
        <Link to='/lab/b'>B</Link>
      </div>

      <div>
        {transition((style,item) => {
          return (
            <a.div style={style}>
              <Switch location={item}>
                <Route path="/lab/a" exact><div>A</div></Route>
                <Route path="/lab/b" exact><div>B</div></Route>
              </Switch>
            </a.div>
          )
        })}
      </div>
    </>
  )
}

export default Lab

import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { Formik } from 'formik'
import * as yup from 'yup'
import humanizeDuration from 'humanize-duration'
import ProgressBar from 'react-customizable-progressbar'
import DatePicker from 'react-date-picker'
import { useQuery, useMutation, useSubscription, gql } from '@apollo/client'
import Spin from '../../components/Spin'
import Error from '../../components/Error'
import Label from '../../components/Label'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Input from '../../components/Input'
import InputMask from '../../components/InputMask'
import Textarea from '../../components/Textarea'
import FormErrorBox from '../../components/FormErrorBox'
import { Flex, Box } from '../../components/FlexBox'
import Tooltip from '../../components/Tooltip'
import DropdownMultipleCombobox from '../../components/DropdownMultipleCombobox'
import { CloseIcon } from '../../components/Icons'
import { useMessageContext } from '../../contexts/MessageContext'

const BASE_UPLOAD = process.env.REACT_APP_BASE_UPLOAD

function humanFileSize(size, language) {
  const i = Math.floor( Math.log(size) / Math.log(1024) )
  let value = (size / Math.pow(1024, i)).toFixed(2) * 1
  return `${value.toLocaleString(language)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`
}

const parseHeaders = (rawHeaders) => {
  const headers = new Headers()
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':')
    const key = parts.shift().trim()
    if (key) {
      const value = parts.join(':').trim()
      headers.append(key, value)
    }
  })
  return headers
}

function New({ onRequestClose, onCreate, data: { allClasses } }) {
  const { t, i18n } = useTranslation()

  const { message } = useMessageContext()

  const [videoFile, setVideoFile] = React.useState(null)
  const [uuidState, setUuidState] = React.useState(null)
  const [percentUpload, setPercentUpload] = React.useState(null)
  const [processingPercent, setProcessingPercent] = React.useState(null)
  const [processingStatus, setProcessingStatus] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const [videoThumb, setVideoThumb] = React.useState(null)
  const [videoDuration, setVideoDuration] = React.useState(null)
  console.log('videoDuration', videoDuration)

  const [createLesson] = useMutation(gql`mutation ($title: String, $description: String, $recordedAt: Date, $classes: [ID]) { createLesson(title: $title, description: $description, recordedAt: $recordedAt, classes: $classes) { uuid } }`)

  useSubscription(
    gql`subscription ($uuid: ID!) { uploadStatusChanged(uuid: $uuid) }`,
    {
      skip: !uuidState,
      variables: { uuid: uuidState },
      onSubscriptionData: ({ client, subscriptionData: { data: { uploadStatusChanged } } }) => {
        setTimeout(() => {
          if (uploadStatusChanged?.uuid && uploadStatusChanged?.progress?.status && uploadStatusChanged?.progress?.status === 'CONVERTED') {
            message(t('Successfully created!'))
            onCreate()
            onRequestClose()
          } else if (uploadStatusChanged?.uuid && uploadStatusChanged?.progress?.status && uploadStatusChanged?.progress?.status === 'UPLOADING' && uploadStatusChanged?.progress?.percentage) {
            // const uuid = uploadStatusChanged.uuid
            const percent =  Math.round(uploadStatusChanged.progress.percentage)
            if (percent && percent > percentUpload) {
              setPercentUpload(current => {
                if (current < percent) return percent
                return current
              })
            }
          } else {
            setProcessingStatus(uploadStatusChanged?.progress?.status)
            if (uploadStatusChanged?.progress?.percentage) setProcessingPercent(Math.round(uploadStatusChanged.progress.percentage))
          }
        }, 100)
      }
    }
  )

  const validationSchema = yup.object().shape({
    title: yup.string().required(t('Required field'))
  })

  const handleFileChange = async ({ target: { validity, files: [file] } }) => {
    if (!validity.valid) {
      return alert('Vídeo inválido')
    }
    if (file.size > 2000000000) {
      return alert('Este vídeo tem o tamanho maior que o limite de 2GB')
    }
    if (file.type !== 'video/mp4') {
      return alert('Apenas vídeos mp4 são permitidos')
    }

    setLoading(true)

    // https://codepen.io/aertmann/pen/mAVaPx?editors=1010
    const fileReader = new FileReader()
    // if (file.type.match('image')) {
    //   fileReader.onload = function() {
    //     var img = document.createElement('img');
    //     img.src = fileReader.result;
    //     document.getElementsByTagName('div')[0].appendChild(img);
    //   };
    //   fileReader.readAsDataURL(file);
    // } else {



      fileReader.onload = function() {
        var blob = new Blob([fileReader.result], {type: file.type});
        var url = URL.createObjectURL(blob);
        var video = document.createElement('video');
        var timeupdate = function() {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('loadeddata', function() {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });
        var snapImage = function() {
          var canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
          var image = canvas.toDataURL();
          // var success = image.length > 100000;
          var success = true;
          if (success) {
            setVideoThumb(image)
            setVideoDuration(Math.round(video.duration) * 1000)
            setLoading(false)
            // var img = document.createElement('img');
            // img.src = image;
            // document.getElementsByTagName('div')[0].appendChild(img);
            // URL.revokeObjectURL(url);
          }
          return success;
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      fileReader.readAsArrayBuffer(file);


    setVideoFile(file)
    // const blobURL = URL.createObjectURL(file)
    // document.querySelector('video').src = blobURL
  }

  const handleSubmit = async variables => {
    if (!videoFile) { return alert(t('Select video to continue')) }

    console.log('variables', variables)

    const classesIds = variables.classes.map(item => item.id)

    const result = await createLesson({ variables: { title: variables.title, description: variables.description, recordedAt: variables.recordedAt, classes: classesIds } })
    const uuid = result.data.createLesson.uuid
    setUuidState(uuid)

    const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)

    const formData = new FormData()
    formData.append('uuid', uuid)
    formData.append('video', videoFile)
    formData.append('from', variables.from)
    formData.append('to', variables.to)

    const headers = { 'Authorization': `Bearer ${token}` }

    const xhr = new XMLHttpRequest()

    xhr.onload = () => {
      const opts = { status: xhr.status, statusText: xhr.statusText, headers: parseHeaders(xhr.getAllResponseHeaders() || '') }
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get("X-Request-URL")
      const body = 'response' in xhr ? xhr.response : xhr.responseText
      const response = new Response(body, opts)
    }
    xhr.onerror = () => {
      console.log('onerror')
      // reject(new TypeError('Network request failed'))
    }
    xhr.ontimeout = () => {
      console.log('ontimeout')
      // reject(new TypeError('Network request failed'))
    }
    xhr.open('POST', `${BASE_UPLOAD}/upload`, true)
    Object.keys(headers).forEach(key => {
      xhr.setRequestHeader(key, headers[key])
    })

    if (xhr.upload) {
      xhr.upload.onprogress = ev => {
        let percent = Math.round(((ev.loaded/ev.total) + Number.EPSILON) * 100) / 100
        percent = percent / 1.5
        percent = Math.round(percent * 100)
        if (!percentUpload || percent > percentUpload) {
          setPercentUpload(current => {
            if (current < percent) return percent
            return current
          })
        }
      }
    }

    xhr.send(formData)

  }

  return (
    <Flex width={1} flexDirection='column'>
      <Flex width={1} justifyContent='space-between'>
        <Flex width={48}></Flex>
        <Flex alignItems='center' textAlign='center'>
          <Text strong large>{t('New lesson')}</Text>
        </Flex>
        <Flex width={48} p={1} justifyContent='flex-end'>
          <Tooltip tooltip={`${t('Close')} (esc)`} offset={8}><Box p={2} display='inline-flex' cursor='pointer' onClick={onRequestClose}><CloseIcon /></Box></Tooltip>
        </Flex>
      </Flex>
      <Box width={1} overflow='auto'>
        <Flex flexDirection='column'>
          <Box p={4}>
            {percentUpload ? (
              <>
                {(percentUpload === 100 || processingPercent) ? (
                  <Box width={240} mb={4} css={css`margin: 0 auto;`}>
                    <ProgressBar
                      radius={100}
                      progress={processingPercent}
                      strokeWidth={10}
                      strokeColor='#5d9cec'
                      strokeLinecap='square'
                      trackStrokeWidth={10}
                    >
                      <div className='indicator' css={css`display: flex; justify-content: center; text-align: center; position: absolute; top: 0; width: 100%; height: 100%; margin: 0 auto; align-items: center;`}>
                        <div>
                          <div>{t('Processing')}...</div>
                          <div>{processingPercent || '0'}%</div>
                        </div>
                      </div>
                    </ProgressBar>
                  </Box>
                ) : (
                  <Box width={240} mb={4} css={css`margin: 0 auto;`}>
                    <ProgressBar
                      radius={100}
                      progress={percentUpload}
                      strokeWidth={10}
                      strokeColor='#5d9cec'
                      strokeLinecap='square'
                      trackStrokeWidth={10}
                    >
                      <div className='indicator' css={css`display: flex; justify-content: center; text-align: center; position: absolute; top: 0; width: 100%; height: 100%; margin: 0 auto; align-items: center;`}>
                        <div>
                          <div>{t('Uploading')}...</div>
                          <div>{percentUpload || '0'}%</div>
                        </div>
                      </div>
                    </ProgressBar>
                  </Box>
                )}
              </>
            ) : (
              <Formik
                initialValues={{ title: '', description: '', recordedAt: '', from: '', to: '', classes: [] }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue }) => (
                  <Box>
                    <form autoComplete='nope' onSubmit={handleSubmit}>
                    <Box>
                      <Flex>
                        <Box width={300} mr={4}>
                          {videoFile ? (
                            <>
                              {(!loading && videoThumb) ? (
                                <Box>
                                  <Box mb={2}>
                                    <img src={videoThumb} style={{ width: 300 }} />
                                  </Box>
                                  {videoFile && (
                                    <Box mb={1}>
                                      {humanFileSize(videoFile.size, i18n.language)}
                                    </Box>
                                  )}
                                  {videoDuration && (
                                    <Box>{humanizeDuration(videoDuration, { language: i18n.language, fallbacks: ['en'] })}</Box>
                                  )}
                                  <Box mt={4}>
                                    <Box mb={2}><Text small>Você pode utilizar os campos abaixo para cortar o vídeo, definindo o tempo inicial e/ou final. Se não quiser cortar, deixe em branco.</Text></Box>
                                    <Flex mb={4}>
                                      <Box mr={3}>
                                        <Box><Label>{t('From')}</Label></Box>
                                        <Box><InputMask width='140px' mask='99:99:99' placeholder='Ex.: 00:10:20' name='from' value={values.from} onBlur={handleBlur} onChange={handleChange} /></Box>
                                      </Box>
                                      <Box>
                                        <Box><Label>{t('To')}</Label></Box>
                                        <Box><InputMask width='140px' mask='99:99:99' placeholder='Ex.: 01:00:00' name='to' value={values.to} onBlur={handleBlur} onChange={handleChange} /></Box>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </Box>
                              ) : (
                                <Flex justifyContent='center'><Spin size={50} /></Flex>
                              )}
                            </>
                          ) : (
                            <label htmlFor='input-file' css={css`position: relative; display: block; cursor: pointer; border: 2px dashed ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'grey'}; padding: 54px; min-height: 150px; background: white; text-align: center; line-height: 1.3;`}>
                              <span>Arraste e solte o arquivo de vídeo ou clique para selecionar</span>
                              <input css={css`position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;`} id='input-file' type='file' required onChange={handleFileChange} accept='video/mp4,video/x-m4v,video/*' />
                            </label>
                          )}
                        </Box>
                        <Box flex={1}>



                                <Box>
                                  <Label required>{t('Title')}</Label>
                                  <Input width='100%' name='title' value={values.title} onBlur={handleBlur} onChange={handleChange} invalid={errors && errors.title} />
                                  <FormErrorBox fieldName='title' errors={errors} touched={touched} />
                                </Box>

                                <Box>
                                  <Label>{t('Description')}</Label>
                                  <Textarea width='100%' name='description' value={values.description} onBlur={handleBlur} onChange={handleChange} />
                                  <FormErrorBox fieldName='description' errors={errors} touched={touched} />
                                </Box>

                                <Box mb={4}>
                                  <Label>{t('Recorded at')}</Label>
                                  <Box><DatePicker clearIcon={(values.recordedAt ? 'X' : null)} maxDate={new Date()} locale='pt-BR' value={values.recordedAt} onChange={date => { setFieldValue('recordedAt', date) }} /></Box>
                                </Box>

                                <Box mb={3}>
                                  <Label>{t('Disponibilizar esta aula para a(s) turma(s)')}</Label>
                                  <DropdownMultipleCombobox name='classes' items={allClasses} value={values.classes} valueField='id' labelField='name' onChange={selectedItems => setFieldValue('classes', selectedItems)} />
                                  <FormErrorBox fieldName='classes' errors={errors} touched={touched} />
                                </Box>




                        </Box>
                      </Flex>
                    </Box>
                    <Box textAlign='center'>
                      <Button type='submit' disabled={!videoFile}>{t('Enviar vídeo e salvar')}</Button>
                    </Box>
                    </form>
                  </Box>
                )}
              </Formik>
            )}
          </Box>
        </Flex>
      </Box>

    </Flex>
  )
}

const NewQuery = ({ onRequestClose, onCreate }) => {
  const { loading, error, data } = useQuery(gql`query { allClasses { id, name } }`, { fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return <New data={data} onRequestClose={onRequestClose} onCreate={onCreate} />
}

export default NewQuery

import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTransition, a, config } from 'react-spring'
import useLockBodyScroll from '../hooks/useLockBodyScroll'
import styled from 'styled-components/macro'

const ModalContent = styled(a.div)`
  position: relative;
  z-index: 1001;
  margin: 0 auto;
  display: flex;

  ${props => (props.$bg && `background: ${props.theme && props.theme.colors && props.theme.colors[props.$bg] ? props.theme.colors[props.$bg] : props.$bg};`)}
  ${props => (props.$boxShadow && `box-shadow: ${props.$boxShadow};`)}

  ${props => (props.$minWidth && `min-width: ${props.$minWidth}${/^\d+$/.test(props.$minWidth.toString()) ? 'px' : ''} !important;`)}
  ${props => (props.$maxWidth && `max-width: ${props.$maxWidth}${/^\d+$/.test(props.$maxWidth.toString()) ? 'px' : ''} !important;`)}
  ${props => (props.$minHeight && `min-height: ${props.$minHeight}${/^\d+$/.test(props.$minHeight.toString()) ? 'px' : ''} !important;`)}
  ${props => (props.$maxHeight && `max-height: ${props.$maxHeight}${/^\d+$/.test(props.$maxHeight.toString()) ? 'px' : ''} !important;`)}
`

const ModalBody = ({ children }) => {
  const mainDivRef = useRef(document.createElement('div'))
  const modalRootRef = useRef(document.getElementById('modal-root'))

  useEffect(() => {
    const modalRoot = modalRootRef.current
    if (!modalRoot) throw new Error('No modal-root exists!')
    const mainDiv = mainDivRef.current
    modalRoot.appendChild(mainDiv)
    return () => {
      modalRoot.removeChild(mainDiv)
    }
  }, [])

  return createPortal(children, mainDivRef.current)
}

const Modal = ({ children, onCancel, closeOnClickOutside = true, shown, immediate = false, bg = 'bodyBg', backdropColor = 'hsla(0, 0%, 0%, 0.7)', boxShadow = '0px 4px 44px hsla(0, 0%, 0%, 0.07)', minWidth = 300, maxWidth = 800, minHeight = 300, maxHeight = 'calc(100vh - 64px)', top = 32 }) => {
  useLockBodyScroll(shown)

  const parentDiv = useRef(null)

  useEffect(() => {
    if (shown && parentDiv.current) parentDiv.current.focus()
  }, [shown])

  const keyHandler = e => {
    if (shown && e.key === 'Escape') {
      e.preventDefault()
      e.stopPropagation()
      onCancel()
    }
  }

  const transition = useTransition(shown, {
    immediate,
    from: { opacity: 0, top: -1000 },
    enter: {
      opacity: 1, top,
      config: item => {
        if (item === 'top') return { ...config.stiff }
        return { ...config.default, duration: 500 }
      }
    },
    leave: {
      opacity: 0,// top: -1000,
      config: item => {
        // if (item === 'top') return { ...config.stiff }
        return { ...config.default, duration: 100 }
      }
    },
  })

  if (!shown) return null // FIXME animation ??

  return transition((props, item) => {
    return (
      item && (
        <ModalBody>
          <div
            ref={parentDiv}
            onKeyDown={keyHandler}
            tabIndex={0}
            aria-modal='true'
            role='dialog'
            style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, overflow: 'hidden', outline: 'none' }}
          >
            <ModalContent $bg={bg} $boxShadow={boxShadow} $minWidth={minWidth} $maxWidth={maxWidth} $minHeight={minHeight} $maxHeight={maxHeight} style={{ top: props.top, opacity: props.opacity }}>
              {children({ onRequestClose: onCancel })}
            </ModalContent>
            <a.div onClick={() => { if (closeOnClickOutside) { onCancel() } }} style={{ position: 'fixed', zIndex: 1000, top: 0, right: 0, bottom: 0, left: 0, backgroundColor: backdropColor, outline: 'none', tabIndex: -1, opacity: props.opacity }} />
          </div>
        </ModalBody>
      )
    )
  })
}

export default Modal

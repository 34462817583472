import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'
import * as yup from 'yup'

import { Box } from '../../components/FlexBox'
import { useMessageContext } from '../../contexts/MessageContext'
import { useAppContext } from '../../contexts/AppContext'

import Form from './Form'

function Login() {
  const { t } = useTranslation()

  const initialValues = { email: '', password: '' }
  const validationSchema = yup.object().shape({
    email: yup.string().email(t('Invalid email')).required(t('Required field')),
    password: yup.string().required(t('Required field')),
  })

  const history = useHistory()
  const { message } = useMessageContext()
  const { setItemState } = useAppContext()
  const [signIn] = useMutation(gql`mutation($email: String!, $password: String!) { signIn(email: $email, password: $password) { token, me { id, name, email, role } } }`)

  const handleSubmit = async (variables, { setSubmitting }) => {
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)

    try {
      const result = await signIn({ variables })
      if (result.data.signIn.token) {
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, result.data.signIn.token)
        setItemState({ me: result.data.signIn.me })
        history.push(`/`)
      }
    } catch(error) {
      setSubmitting(false)
      console.error(error)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(t(errorMessage))
    }
  }

  return (
    <Box pt={5}>
      <Box mx='auto' maxWidth={300}>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>
      </Box>
    </Box>
  )
}

export default Login

import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

const Textarea = forwardRef(({ width, invalid, ...rest }, ref) => {
  return (
    <textarea {...rest} ref={ref} />
  )
})

const StyledTextarea = styled(Textarea)`
  font-family: inherit;
  font-size: inherit;
  padding: 0.5em 0.8em;
  color: inherit;

  background: ${props => (props.theme && props.theme.colors && props.theme.colors.white) ? props.theme.colors.white : 'white'};
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'grey'};
  ${props => props.width && css`width: ${props.width};`}
  ${props => props.invalid && css`border-color: ${props.theme && props.theme.colors && props.theme.colors.error ? props.theme.colors.error : 'red'} !important;`}

  /* &:not(:placeholder-shown):invalid {
    border-color: ${props => (props.theme && props.theme.colors && props.theme.colors.error) ? props.theme.colors.error : 'red'};
  } */

  resize: none;
  height: 70px;

  &::placeholder {
    color: black;
    opacity: 0.15;
  }

  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'lightgrey'};
  }
`

export default StyledTextarea

import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { Formik } from 'formik'
import { gql, useQuery, useMutation } from '@apollo/client'
import * as yup from 'yup'

import Spin from '../../components/Spin'
import Error from '../../components/Error'
import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import Tooltip from '../../components/Tooltip'
import { useMessageContext } from '../../contexts/MessageContext'
import { useAppContext } from '../../contexts/AppContext'

import Form from './Form'

function Edit({ data: { student: item }, onRequestClose }) {
  const { t } = useTranslation()
  const { setItemState } = useAppContext()

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('Required field')),
  })

  const { message } = useMessageContext()
  const [updateStudent] = useMutation(gql`mutation ($id: ID!, $name: String!, $email: String, $cellPhoneNumber: String, $obs: String) { updateStudent(id: $id, name: $name, email: $email, cellPhoneNumber: $cellPhoneNumber, obs: $obs) { id, name, email, cellPhoneNumber, obs } }`)

  const handleSubmit = async (variables, actions) => {
    try {
      await updateStudent({ variables: { id: item.id, ...variables } })
      message(t('Successfully saved!'))
      setItemState({ studentDetails: null })
      onRequestClose()
    } catch(error) {
      console.error(error)
      actions.setSubmitting(false)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(t(errorMessage))
    }
  }

  return (
    <Box width={1}>
      <Flex p={1} justifyContent='flex-end'>
        <Tooltip tooltip={`${t('Close')} (esc)`} offset={8}><Box p={2} display='inline-flex' cursor='pointer' onClick={onRequestClose}><CloseIcon /></Box></Tooltip>
      </Flex>
      <Box p={4} css={css`overflow: auto; height: calc(100vh - 48px);`}>
        <Formik
          enableReinitialize
          initialValues={item}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {props => <Form {...props} />}
        </Formik>
      </Box>
    </Box>
  )
}

const EditQuery = ({ id, onRequestClose }) => {
  const { loading, error, data } = useQuery(gql`query ($id: ID!) { student(id: $id) { id, name, email, cellPhoneNumber, obs } }`, { variables: { id }, fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return <Edit data={data} onRequestClose={onRequestClose} />
}

export default EditQuery

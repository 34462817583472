import React from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useQuery, useMutation, gql } from '@apollo/client'
import Spin from '../../components/Spin'
import Error from '../../components/Error'
import { Flex, Box } from '../../components/FlexBox'
import Tooltip from '../../components/Tooltip'
import { CloseIcon } from '../../components/Icons'
import { useMessageContext } from '../../contexts/MessageContext'

import MultiStepForm from './MultiStepForm'

const BASE_API = process.env.REACT_APP_BASE_API

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function NewMultiStep({ data: { allStudents }, onRequestClose, onCreate }) {
  const { t } = useTranslation()

  const { message } = useMessageContext()

  const validationSchemas = [
    yup.object().shape({
      name: yup
        .string()
        .required(t('Required field'))
        .test('name', t('There is a class registered with this name'), async (value) => {
          try {
            const response = await (await fetch(`${BASE_API}/check-class-name/${value.trim()}`)).json()
            return !response.exists
          } catch(err) {
            console.error(err)
            return true
          }
        }),
    }),
    yup.object().shape({})
  ]

  const [createClass] = useMutation(gql`mutation ($name: String!, $students: [ID]) { createClass(name: $name, students: $students) { id, createdAt, name, students { id, name } } }`)

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = ['Detalhes', 'Alunos', 'Review your order']
  const isLastStep = activeStep === steps.length - 1
  const currentValidationSchema = validationSchemas[activeStep]

  const initialValues = { name: '', students: [] }

  async function handleSubmit(variables, actions) {
    if ([0, 1].includes(activeStep)) await sleep(300)

    if (!isLastStep) return setActiveStep(activeStep + 1)

    try {
      const result = await createClass({ variables: { ...variables, students: variables.students.map(item => item.id) } })
      onCreate()
      onRequestClose()
      message(t('Successfully created!'))
    } catch(error) {
      console.error(error)
      actions.setSubmitting(false)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(t(errorMessage))
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1)
  }

  // https://medium.com/@nphivu414/build-a-multi-step-form-with-react-hooks-formik-yup-and-materialui-fa4f73545598
  return (
    <Box width={1}>
      <Flex p={1} justifyContent='flex-end'>
        <Tooltip tooltip={`${t('Close')} (esc)`} offset={8}><Box p={2} display='inline-flex' cursor='pointer' onClick={onRequestClose}><CloseIcon /></Box></Tooltip>
      </Flex>
      <Box p={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {props => (
            <MultiStepForm
              {...props}
              steps={steps}
              activeStep={activeStep}
              isLastStep={isLastStep}
              onBack={handleBack}
              students={allStudents}
              isCreating
            />
          )}
        </Formik>
      </Box>
    </Box>
  )

}

const NewMultiStepQuery = ({ onRequestClose, onCreate }) => {
  const { loading, error, data } = useQuery(gql`query { allStudents { id, name } }`, { fetchPolicy: 'network-only' })
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return (
    <NewMultiStep data={data} onRequestClose={onRequestClose} onCreate={onCreate} />
  )
}

export default NewMultiStepQuery

import { useEffect } from 'react'

function ForceRedirect({ to: { pathname } }) {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = pathname
    }, 0)
  }, [pathname])

  return null
}

export default ForceRedirect

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { css } from 'styled-components/macro'
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params'
import useScrollPosition from '../../hooks/useScrollPosition'
import { Flex, Box } from '../../components/FlexBox'
import ModalDetails from '../../components/ModalDetails'
import Pagination from '../../components/Pagination'
import Label from '../../components/Label'
import ButtonSmall from '../../components/ButtonSmall'
import LinkButtonSmall from '../../components/LinkButtonSmall'
import { CloseIcon } from '../../components/Icons'
import { TableContainer, Table, THead, TBody, TR, TH, THSticky, TD, TDSticky, Truncate, SortLabel } from '../../components/TableSticky'
import { useMessageContext } from '../../contexts/MessageContext'
import { useAppContext } from '../../contexts/AppContext'
import { FormatDateTime } from '../../components/Format'

import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client'
import Error from '../../components/Error'
import Spin from '../../components/Spin'

import paths from '../../paths'

const BASE_PATH = paths.classes

const LIST = gql`query($limit: Int, $offset: Int, $search: String, $orderBy: String) {
  classes(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
    count
    results {
      id
      createdAt
      name
      students {
        id
        name
      }
      lessons {
        id
        title
      }
    }
  }
}`

const PAGE_SIZE = 30
const INITIAL_ORDER = 'createdAt asc'

const List = React.forwardRef(({ data: { classes: { results: items, count } }, refetch, variables }, ref) => {
  const { t } = useTranslation()
  const { state, setItemState } = useAppContext()
  const client = useApolloClient()

  const [{ p: page, o: order }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), o: withDefault(StringParam, INITIAL_ORDER) })
  let [field, ascOrDesc] = order ? order.split(' ') : ['', '']

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  const { message } = useMessageContext()
  const [deleteClass] = useMutation(gql`mutation ($id: ID!) { deleteClass(id: $id) }`)

  const [showOnScroll, setShowOnScroll] = React.useState(false)

  const tableContainerRef = React.useRef()
  const tableRef = React.useRef()

  // https://stackoverflow.com/questions/37949981/call-child-method-from-parent
  React.useImperativeHandle(ref, () => ({
    callRefetch() {
      refetch()
    }
  }))

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.x > 0
      if (isShow !== showOnScroll) setShowOnScroll(isShow)
    },
    [showOnScroll],
    tableRef,
    tableContainerRef
  )

  React.useEffect(() => {
    const handleVisibilityChange = e => {
      if (typeof document.hidden !== 'undefined' && !document.hidden) {
        refetch()
      }
    }
    if (typeof document.addEventListener !== 'undefined' && typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [refetch])

  const handlePageChange = ({ selected }) => {
    if (selected) {
      setQuery({ p: (selected + 1) })
    } else {
      setQuery({ p: undefined })
    }
  }

  const handleDelete = async id => {
    if (!window.confirm(t('Are you sure you want to delete this item?'))) return false

    try {
      await deleteClass({
        variables: { id },
        update: (_, { data: { deleteClass } }) => {
          if (!deleteClass) return false
          refetch()
          setItemState({ classDetails: null })
        }
      })
      message(t('Successfully deleted!'))
    } catch(error) {
      console.error(error)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(t(errorMessage))
    }
  }

  const handleDetails = id => {
    const { classes } = client.readQuery({ query: LIST, variables })
    const foundClass = classes.results.find(item => item.id === id)
    setItemState({ classDetails: foundClass })
  }


  return (
    <>
      <Flex flexDirection='column' justifyContent='space-between' overflow='hidden'>
        <TableContainer ref={tableContainerRef}>
          <Table width='calc(100vw - 209px)' ref={tableRef}>
            <THead>
              <TR>
                <THSticky width='300px' showShadow={showOnScroll}>
                  <SortLabel label={t('Name')} headerField={'name'} currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />
                </THSticky>
                <TH width='160px'>
                  <SortLabel label={t('Created')} headerField={'createdAt'} currentField={field} currentAscOrDesc={ascOrDesc} onChange={o => setQuery({ o, p: undefined })} />
                </TH>
                <TH width='100px'>{t('Students')}</TH>
                <TH width='100px'>{t('Lessons')}</TH>
              </TR>
            </THead>
            <TBody>
              {items.map(item => (
                <TR key={item.id}>
                  <TDSticky css={css`text-decoration: underline; text-underline-position: under; text-decoration-color: hsl(0deg 0% 90%); text-decoration-thickness: 2px; cursor: pointer; &:hover { text-decoration-color: inherit; }`} showShadow={showOnScroll} onClick={() => handleDetails(item.id)}>
                    {item.name}
                  </TDSticky>
                  <TD><FormatDateTime>{item.createdAt}</FormatDateTime></TD>
                  <TD>{item.students.length || ''}</TD>
                  <TD>{item.lessons.length || ''}</TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </TableContainer>
        <Box p={2} height={60} css={css`border-top: 1px solid #f0f4f5; background: white;`}>
          <Pagination onPageChange={handlePageChange} currentPage={page} pageCount={pageCount} />
        </Box>
      </Flex>

      <ModalDetails immediate={false} shown={!!state.classDetails}>
        {() => (
          <Box width={1}>
            <Flex p={1} justifyContent='flex-end'>
              <Box p={2} display='inline-flex' cursor='pointer' onClick={() => setItemState({ classDetails: null })}><CloseIcon /></Box>
            </Flex>
            <Box px={4} pb={4} css={css`overflow: auto; height: calc(100vh - 48px);`}>
              <Box mb={4}>
                <Label>{t(`Class name`)}:</Label>
                <Box>{state.classDetails.name}</Box>
              </Box>
              {!!state.classDetails.students.length && (
                <>
                  <Box mb={1}><Label>{t('Students')}</Label></Box>
                  <Box mb={4} css={css`overflow: scroll; max-height: calc(70vh - 150px); min-height: 40px;`}>
                    {state.classDetails.students.map(item => (
                      <Box key={item.id} mb={1}>- {item.name}</Box>
                    ))}
                  </Box>
                </>
              )}
              <Flex>
                <Box mr={3}><LinkButtonSmall to={`/${BASE_PATH}/${state.classDetails.id}/${paths.common.edit}${window.location.search}`}>{t('Edit')}</LinkButtonSmall></Box>
                <Box><ButtonSmall onClick={() => handleDelete(state.classDetails.id)}>{t('Delete')}</ButtonSmall></Box>
              </Flex>
            </Box>

          </Box>
        )}
      </ModalDetails>

    </>
  )
})

const ListQuery = React.forwardRef(({ search, page, orderBy }, ref) => {
  const variables = { limit: PAGE_SIZE, offset: (page - 1) * PAGE_SIZE, search, orderBy }
  const { loading, error, data, refetch } = useQuery(LIST, { fetchPolicy: 'network-only', variables })
  if (loading) return <Spin />
  if (error) return <Error error={error} />
  return <List data={data} refetch={refetch} ref={ref} variables={variables} />
})

export default ListQuery

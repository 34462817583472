import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppContext } from '../contexts/AppContext'
import ForceRedirect from '../components/ForceRedirect'

function PrivateRoute({ children, me = null, ...rest }) {

  const { setItemState, state } = useAppContext()

  React.useEffect(() => {
    if (me) setItemState({ me })
  }, [me, setItemState])

  return (
    <Route {...rest} render={
      ({ location }) => {
        if (me) return <ForceRedirect to={{ pathname: location.pathname }} />
        if (state.me) return children
        return <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    } />
  )

}

export default PrivateRoute

const routes = {
  login: 'login',
  about: 'sobre',
  lab: 'lab',
  students: 'students',
  classes: 'classes',
  lessons: 'lessons',
  common: {
    edit: 'edit',
    new: 'new',
  }
}

export default routes

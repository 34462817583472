import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Box } from './FlexBox'

export const FormError = styled.div`
  font-size: 10px;
  line-height: 18px;
  ${props => css`color: ${props.theme && props.theme.colors && props.theme.colors.error ? props.theme.colors.error : 'red'};`}
  text-transform: uppercase;
  font-weight: 500;
`

 function FormErrorBox({ errors, touched, fieldName }) {
   return (
    <Box minHeight={18}>
      {errors[fieldName] && touched[fieldName] && <FormError>{errors[fieldName]}</FormError>}
    </Box>
   )
}

export default FormErrorBox

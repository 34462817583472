import React from 'react'
import { BrowserRouter, Switch, Route, } from 'react-router-dom'

import { QueryParamProvider } from 'use-query-params'
import { ThemeProvider } from 'styled-components'

import useMobileDetect from './hooks/useMobileDetect'

import { AppProvider, useAppContext } from './contexts/AppContext'
import { MessageProvider } from './contexts/MessageContext'

import GlobalStyle from './GlobalStyle'
import themes from './themes'

import AutoLogout from './components/AutoLogout'
import WithMe from './components/WithMe'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'

import MainLayout from './layouts/MainLayout'
// import UserLayout from './layouts/UserLayout'
import LoginLayout from './layouts/LoginLayout'

import Dashboard from './containers/Dashboard'
import Login from './containers/Login'
import Users from './containers/Users'
import Classes from './containers/Classes'
import Lessons from './containers/Lessons'

import Lab from './containers/Lab'

import paths from './paths'

const About = React.lazy(() => import('./containers/About'))

function App() {

  useMobileDetect()

  const { state } = useAppContext()
  const theme = themes[state.theme]

  return (
    <BrowserRouter>

      <QueryParamProvider ReactRouterRoute={Route}>

        <ThemeProvider theme={theme}>

          <GlobalStyle />

          <AutoLogout />

          <MessageProvider>

            <Switch>
              <Route path={`/${paths.about}`} exact>
                <React.Suspense fallback={<div>Loading...</div>}>
                  <About />
                </React.Suspense>
              </Route>

              <Route path={[`/${paths.login}`]}>
                <LoginLayout>
                  <Switch>
                    <PublicRoute path={`/${paths.login}`} redirectTo={`/${paths.reports}`}>
                      <Login />
                    </PublicRoute>
                  </Switch>
                </LoginLayout>
              </Route>

              <Route path='/lab'>
                <Lab />
              </Route>

              <Route path={[`/`, `/${paths.students}`, `/${paths.classes}`]}>
                <MainLayout>
                  <Switch>
                    <PrivateRoute exact path={`/`}>
                      <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute path={`/${paths.students}`}>
                      <Users />
                    </PrivateRoute>
                    <PrivateRoute path={`/${paths.classes}`}>
                      <Classes />
                    </PrivateRoute>
                    <PrivateRoute path={`/${paths.lessons}`}>
                      <Lessons />
                    </PrivateRoute>
                  </Switch>
                </MainLayout>
              </Route>

            </Switch>

          </MessageProvider>

        </ThemeProvider>

      </QueryParamProvider>

    </BrowserRouter>
  )

}

function AppWithProvider() {
  const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
  return (
    token ? (
      <WithMe>{({ data }) => <AppProvider defaultValue={{ theme: 'default', me: data.me }}><App /></AppProvider>}</WithMe>
    ) : (
      <AppProvider defaultValue={{ theme: 'default' }}><App /></AppProvider>
    )
  )
}


export default AppWithProvider

import styled, { css } from 'styled-components'

const Label = styled.label`
  font-weight: 500;
  line-height: 2em;
  text-transform: uppercase;
  font-size: 12px;
  &::after {
    ${props => props.required && css`content: '*';`}
  }
`

export default Label

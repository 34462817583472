import React from 'react'
import { Box } from '../components/FlexBox'
import WrapperLayout from './WrapperLayout'

const LoginLayout = ({ children }) => {

  return (
    <WrapperLayout>
      <Box>
        {children}
      </Box>
    </WrapperLayout>
  )
}

export default LoginLayout

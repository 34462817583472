import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkButton = ({ children, to, className, ...rest }) => (
  <Link to={to} className={className} {...rest}>
    {children}
  </Link>
)

const StyledLinkButton = styled(LinkButton)`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  user-select: none;

  line-height: 1;
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  color: white;
  background-color: ${props => props.theme?.colors?.primary || 'darkgrey'};
  border-radius: 4px;
  padding: 0.5em 0.8em;

  &:hover {
    background: ${props => props.theme?.colors?.primary600 || 'black'};
  }
  &:focus {
    outline: 3px solid ${props => props.theme?.colors?.grey200 || 'grey'};
  }
  &:active {
    background: ${props => props.theme?.colors?.primary700 || 'black'};
  }
  &:disabled {
    background-color: ${props => props.theme?.colors?.primary300 || 'grey'};
  }
`

export default StyledLinkButton

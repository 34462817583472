import styled, { css } from 'styled-components'
import { space, display, fontSize, fontFamily, lineHeight, fontWeight, letterSpacing, verticalAlign } from 'styled-system'

const Text = styled('span')(
  display,
  space,
  fontSize,
  fontFamily,
  lineHeight,
  fontWeight,
  letterSpacing,
  verticalAlign,
  props => props.pointer && css`cursor: pointer;`,
  props => props.uppercase && css`text-transform: uppercase;`,
  props => props.strong && css`font-weight: 700;`,
  props => props.underline && css`text-decoration: underline;`,
  props => props.extrasmall && css`font-size: 0.75em;`,
  props => props.small && css`font-size: 0.85em;`,
  props => props.normal && css`font-size: 1em;`,
  props => props.large && css`font-size: 1.5em;`,
  props => props.extralarge && css`font-size: 2em;`,
  props => props.white && css`color: ${props => (props.theme && props.theme.colors && props.theme.colors.white) ? props.theme.colors.white : 'white'};`,
  props => props.primary && css`color: ${props => (props.theme && props.theme.colors && props.theme.colors.primary) ? props.theme.colors.primary : 'hsla(207, 73%, 57%, 1)'};`,
  props => props.color && (props.color.includes('grey') || props.color.includes('primary')) && css`color: ${props => (props.theme && props.theme.colors && props.theme.colors[props.color]) ? props.theme.colors[props.color] : props.color};`,
  props => props.css
)

export default Text

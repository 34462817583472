import React from 'react'
import styled from 'styled-components'

const Button = ({ children, className, ...rest }) => {
  return (
    <button className={className} {...rest}>
      {children}
    </button>
  )
}

const StyledButton = styled(Button)`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  user-select: none;

  line-height: 1;
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  color: white;
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.primary ? props.theme.colors.primary : 'darkgrey'};
  border-radius: 4px;
  padding: 0.5em 0.8em;

  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: hsla(0, 0%, 100%, 0.2);
    border-radius: 4px;
    transform: scale(0);
    transition-property: transform, opacity;
  }
  &:hover::before {
    opacity: 1;
    outline: 1px solid transparent;
    transform: scale(1);
  }
  &:active::before {
    background: ${props => props.theme && props.theme.colors && props.theme.colors.primary600 ? props.theme.colors.primary600 : 'black'};
  }
  &:disabled {
    background-color: ${props => props.theme && props.theme.colors && props.theme.colors.primary300 ? props.theme.colors.primary300 : 'grey'};
  }
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'grey'};
  }
`

export default StyledButton

import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'

import { Flex } from '../../components/FlexBox'
import { GenericIcon } from '../../components/Icons'
import LinkButton from '../../components/LinkButton'
import Tooltip from '../../components/Tooltip'
import ModalRight from '../../components/ModalRight'
import Input from '../../components/Input'
import useDebounce from '../../hooks/useDebounce'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import { useAppContext } from '../../contexts/AppContext'

import NewMultiStep from './NewMultiStep'
import Edit from './EditMultiStep'
import List from './List'

import paths from '../../paths'

const BASE_PATH = paths.classes

function Classes() {
  const { t } = useTranslation()

  const { state, setItemState } = useAppContext()

  const history = useHistory()

  const newMatch = useRouteMatch(`/${BASE_PATH}/${paths.common.new}`)
  const editMatch = useRouteMatch(`/${BASE_PATH}/:id/${paths.common.edit}`)

  const inputSearchRef = React.useRef(null)
  const listRef = React.useRef(null)

  const [{ q, p, o }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), q: StringParam, o: StringParam })

  const [name, setName] = React.useState(q || '')
  const debouncedName = useDebounce(name.trim(), 500)

  useIgnoreMount(debouncedName, newDebouncedName => {
    setQuery({ p: undefined, q: newDebouncedName ? newDebouncedName : undefined })
  })

  const handleNewKey = e => {
    e.preventDefault()
    if (!window.location.href.includes(paths.common.edit)) history.push(`/${BASE_PATH}/${paths.common.new}${window.location.search}`)
  }

  const handleSearchKey = e => {
    e.preventDefault()
    inputSearchRef.current.focus()
  }

  return (
    <>
      <GlobalHotKeys keyMap={{ NEW: ['n'], SEARCH: ['f'] }} handlers={{ NEW: handleNewKey, SEARCH: handleSearchKey }} />

      <Flex flexDirection='column' width={`calc(100% - ${state.classDetails ? '300' : '0'}px)`} css={css`transition: width 0.1s; border-right: 1px solid hsl(212deg 27% 90%);`}>
        <Flex p={3} alignItems='center' justifyContent='space-between' css={css`border-bottom: 1px solid hsl(212deg 27% 90%); background: white;`}>
          <Flex>
            <Flex mr={2}>
              <GenericIcon path='M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z' />
            </Flex>
            <Flex css={css`font-size: 24px; font-weight: 500;`}>{t('Classes')}</Flex>
          </Flex>
          <Flex>
            <Input width='300px' ref={inputSearchRef} type='search' placeholder={`${t('Search')}...`} onChange={e => setName(e.target.value)} value={name} />
          </Flex>
          <Flex>
            <Tooltip tooltip={`${t('New class')} (n)`}><LinkButton to={`/${BASE_PATH}/${paths.common.new}${window.location.search}`}>{t('New class')}</LinkButton></Tooltip>
          </Flex>
        </Flex>
        <Flex flexGrow={1}>
          <List ref={listRef} search={debouncedName} page={p} orderBy={o} />
        </Flex>
      </Flex>

      <ModalRight immediate={false} shown={!!newMatch} onCancel={() => history.push(`/${BASE_PATH}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <NewMultiStep onRequestClose={onRequestClose} onCreate={() => { listRef.current.callRefetch() }} />
        )}
      </ModalRight>

      <ModalRight immediate={false} shown={!!editMatch} onCancel={() => { history.push(`/${BASE_PATH}${window.location.search}`); setItemState({ classDetails: null }) }}>
        {({ onRequestClose }) => (
          <Edit id={editMatch?.params?.id} onRequestClose={onRequestClose} />
        )}
      </ModalRight>
    </>
  )
}

export default Classes

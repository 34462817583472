import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import FormErrorBox from './FormErrorBox'
import Textarea from './Textarea'

const FieldTextarea = forwardRef(({ form, field, width, ...rest }, ref) => {
  return (
    <>
      <Textarea {...field} {...rest} ref={ref} />
      <FormErrorBox fieldName={field.name} errors={form.errors} touched={form.touched} />
    </>
  )
})

const StyledTextarea = styled(FieldTextarea)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 8px 12px;
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsl(0 0% 86% / 1)'};
  ${props => props.width && css`width: ${props.width};`}
  ${props => props.form.errors[props.field.name] && props.form.touched[props.field.name] && css`border-color: ${props.theme && props.theme.colors && props.theme.colors.error ? props.theme.colors.error : 'hsl(6 66% 47% / 1)'} !important;`}
  &:focus {
    outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsl(0 0% 96% / 1)'};
  }
`

export default StyledTextarea

import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import styled, { css } from 'styled-components/macro'
import { useHover } from 'use-events'
import { useTransition, a } from 'react-spring'
import { createPortal } from 'react-dom'

import useTimeout from '../hooks/useTimeout'

const styleContent = css`
  position: absolute;
`

const styleArrow = css`
  position: absolute;
  width: 16px;
  height: 16px;
  &:after {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    pointer-events: none;
  }

  &:after {
    border: 8px solid transparent;
  }
`

const styleArrowBottom = css`
  bottom: -8px;

  &:after {
    left: 0;
    bottom: 0;
    border-top-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    border-bottom: none;
  }
`

const styleArrowTop = css`
  top: -8px;

  &:after {
    top: 0;
    left: 0;
    border-bottom-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    border-top: none;
  }
`

const styleArrowLeft = css`
  left: -8px;

  &:after {
    left: 0;
    top: 0;
    border-right-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    border-left: none;
  }
`

const styleArrowRight = css`
  right: -8px;

  &:after {
    right: 0;
    top: 0;
    border-left-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    border-right: none;
  }
`

const Portal = ({ children }) => createPortal(children, document.getElementById('portal-root'))

const PopperContainer = styled.div`
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  border: 1px ${props => props.theme && props.theme.colors && props.theme.colors.grey200 ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  padding: 6px 8px 6px 8px;
  font-size: 11px;
  font-weight: 200;
  border-radius: 4px;
  pointer-events: none;
  z-index: 1002;
`

const Tooltip = ({ children, tooltip, defaultPlacement = 'bottom', offset = 24, immediate = false, delay = 600 }) => {

  // const [left, setLeft] = useState(100)

  // const virtualReference = React.useMemo(() => ({
  //   getBoundingClientRect() {
  //     return {
  //       top: 100,
  //       left,
  //       bottom: 20,
  //       right: 700,
  //       width: 0,
  //       height: 0,
  //     }
  //   },
  // }), [left])

  const [active, bind] = useHover()
  const [show, setShow] = useState(false)

  const timeout = useTimeout(() => {
    setShow(active)
  }, delay)

  let [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: defaultPlacement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, { name: 'offset', options: { offset: [0, offset] } }],
  })

  React.useEffect(() => {
    if (active) {
      if (delay === 0) {
        setShow(active)
      } else {
        timeout.start()
      }
    } else {
      if (delay === 0) {
        setShow(active)
      } else {
        timeout.stop()
        setShow(false)
      }
    }
  }, [active, timeout, delay])

  const transition = useTransition(show, {
    immediate,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const placement = attributes && attributes.popper && attributes.popper["data-popper-placement"]

  return (
    <>
      <span ref={setReferenceElement} {...bind}>{children}</span>
      {transition((springStyle, item) => (
        item && (
          <Portal>
            <a.div style={springStyle}>
              <PopperContainer css={styleContent} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                <div
                  ref={setArrowElement}
                  style={styles.arrow}
                  id='arrow'
                  css={[
                    styleArrow,
                    placement && placement.startsWith('top') && styleArrowBottom,
                    placement && placement.startsWith('bottom') && styleArrowTop,
                    placement && placement.startsWith('left') && styleArrowRight,
                    placement && placement.startsWith('right') && styleArrowLeft
                  ]}
                />
                {tooltip}
              </PopperContainer>
            </a.div>
          </Portal>
        )
        )
      )}
    </>
  )

}

export default Tooltip

import styled, { css } from 'styled-components/macro'
import { Box } from './FlexBox'

const ArrowUpIcon = () => <svg viewBox='0 0 24 24' height={16} fill='currentColor'><path d='M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z' /></svg>
const ArrowDownIcon = () => <svg viewBox='0 0 24 24' height={16} fill='currentColor'><path d='M11,4H13V16L18.5,10.5L19.92,11.92L12,19.84L4.08,11.92L5.5,10.5L11,16V4Z' /></svg>

export function SortLabel({ headerField, currentField, label, currentAscOrDesc, onChange }) {
  if (headerField === currentField) {
    return (
      <div css={css`display: inline-flex; align-items: center; cursor: pointer; text-decoration: underline; text-underline-position: under;`} onClick={() => onChange(`${headerField} ${currentAscOrDesc === 'asc' ? 'desc' : 'asc'}`)}>
        <div css={css`user-select: none;`}>{label}</div>
        <div css={css`display: inline-flex;`}>{(currentAscOrDesc.toLowerCase() === 'desc') ? (<ArrowUpIcon />) : (<ArrowDownIcon />)}</div>
      </div>
    )
  }
  return <div css={css`display: flex; cursor: pointer; display: inline-flex; text-decoration: underline; text-underline-position: under;`} onClick={() => onChange(`${headerField} asc`)}>{label}</div>
}

export const TableContainer = styled(Box)`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: ${props => props.maxHeight || 'calc(100vh - 126px)'};

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  } */
  &::-webkit-scrollbar-thumb {
    background-color: hsl(0deg 0% 0% / 38%);/*var(--thumbBG) ;*/
    /* border-radius: 6px; */
    /* border: 3px solid var(--scrollbarBG); */
  }
`

export const Table = styled.table`
  width: ${props => props.width || '1200px'};
  min-width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
`

export const THead = styled.thead`
  background: rgba(6,6,6,0);
  border: 1px solid transparent;
`

export const TBody = styled.tbody``

export const TR = styled.tr`
  height: 40px;
  &:hover td {
    background: hsl(223deg 47% 97%);
  }
`

export const TH = styled.th`
  position: sticky;
  top: 0px;
  height: 40px;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px 20px;
  background-color: #fafafa;
  font-weight: 500;
  z-index: 2;
`

export const THSticky = styled(TH)`
  left: 0px;
  width: ${props => props.width || '200px'};
  z-index: 3;
  &:after {
    content: '';
    position: absolute;
    top: 0; bottom: -1px; right: 0;
    width: 30px;
    pointer-events: none;
    transition: box-shadow 0.3s;
    box-shadow: ${props => props.showShadow ? 'inset 10px 0 8px -8px hsl(0deg 0% 85%)': 'none'};
    transform: translateX(100%);
  }
`

export const TD = styled.td`
  border-bottom: 1px solid #eee;
  background-color: white;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 21px;
`

export const TDSticky = styled(TD)`
  position: sticky;
  left: 0px;
  &:after {
    content: '';
    position: absolute;
    top: 0; bottom: -1px; right: 0;
    width: 30px;
    pointer-events: none;
    transition: box-shadow 0.3s;
    box-shadow: ${props => props.showShadow ? 'inset 10px 0 8px -8px hsl(0deg 0% 85%)': 'none'};
    transform: translateX(100%);
  }
`
export const Truncate = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

import React from 'react'
import { useCombobox, useMultipleSelection } from 'downshift'
import { css } from 'styled-components/macro'

const selectedItemCss = css`
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  margin-left: 8px;
  border-radius: 8px;
  background: ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
  padding: 4px 8px;
`

const selectedItemIconCss = css`
  cursor: pointer;
  margin-left: 8px;
`

const combomboxCss = css`
  display: inline-block;
  & input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    padding: 8px 12px;
    border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
    ${props => props.width && css`width: ${props.width};`}
    &:focus {
      outline: 3px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
    }
  }
`

const menuMultipleCss = css`
  max-height: 180px;
  overflow-y: auto;
  width: 335px;
  margin: 0;
  border-top: 0;
  background: white;
  position: absolute;
  z-index: 1000;
  list-style: none;
  padding: 0;
  left: 0;
  border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'hsla(0, 0%, 86%, 1)'};
  margin-top: 2px;
  background: ${props => (props.theme && props.theme.colors && props.theme.colors.grey100) ? props.theme.colors.grey100 : 'hsla(0, 0%, 96%, 1)'};
`

const comboboxWrapperCss = css`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
`

function DropdownMultipleCombobox({ name = '', items: initialItems, value: initialSelectedItems, onChange, valueField, labelField }) {

  const [inputValue, setInputValue] = React.useState('')

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems,
    onSelectedItemsChange: ({ selectedItems }) => {
      onChange(selectedItems)
    }
  })

  const getFilteredItems = items => {
    return items.filter(item => {
      return selectedItems.map(item => item[valueField]).indexOf(item[valueField]) < 0 && item[labelField].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(inputValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Za-z0-9\s]/g, ''))
    })
  }

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    openMenu
  } = useCombobox({
    itemToString: item => item ? item[labelField] : '',
    inputValue,
    items: getFilteredItems(initialItems),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('')
            addSelectedItem(selectedItem)
            selectItem(null)
          }

          break
        default:
          break
      }
    },
  })

  return (
    <div name={name} style={{ position: 'relative' }}>
      <div css={comboboxWrapperCss}>
        <div css={combomboxCss} {...getComboboxProps()}>
          <input {...getInputProps(getDropdownProps({preventKeyAction: isOpen}))} />
          <button type='button' {...getToggleButtonProps()} aria-label='toggle menu' css={css`appearance: none; border: none; background: transparent; position: absolute; right: 4px; top: 8px; background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cpath%20fill%3D%22hsla(174%2C%20100%25%2C%2029%25%2C%201)%22%20d%3D%22M16.59%208.59L12%2013.17%207.41%208.59%206%2010l6%206%206-6z%22%2F%3E%3C%2Fsvg%3E"); background-repeat: no-repeat; background-position: right 4px top 50%; background-size: 16px auto; width: 16px; height: 16px;`}></button>
        </div>
      </div>
      <ul {...getMenuProps()} css={menuMultipleCss} style={{ display: isOpen ? 'block' : 'none' }}>
        {isOpen && getFilteredItems(initialItems).map((item, index) => (
            <li
              css={css`padding: 10px 4px; border-bottom: 1px solid hsl(0 0% 0% / 0.1);`}
              style={
                highlightedIndex === index ? {backgroundColor: '#bde4ff'} : {}
              }
              key={`${item[valueField]}${index}`}
              {...getItemProps({ item, index })}
            >
              {item[labelField]}
            </li>
          ))}
      </ul>
      {selectedItems.map((selectedItem, index) => (
          <span css={selectedItemCss} key={`selected-item-${index}`} {...getSelectedItemProps({ selectedItem, index })}>
            {selectedItem[labelField]}
            <span css={selectedItemIconCss} onClick={() => removeSelectedItem(selectedItem)}>&#10005;</span>
          </span>
        ))}
    </div>
  )

}

export default DropdownMultipleCombobox

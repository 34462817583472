import React from 'react'

function isTouchDevice() {
  // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
}

function useMobileDetect() {
  React.useLayoutEffect(() => {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      document.body.classList.add('mobile')
    } else {
      document.body.classList.add('no-mobile')
    }
    if (isTouchDevice()) {
      document.body.classList.add('touch')
    } else {
      document.body.classList.add('no-touch')
    }
  })
}

export default useMobileDetect

import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { GlobalHotKeys } from 'react-hotkeys'
import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'

import { Flex } from '../../components/FlexBox'
import { GenericIcon } from '../../components/Icons'
import LinkButton from '../../components/LinkButton'
import Tooltip from '../../components/Tooltip'
import ModalRight from '../../components/ModalRight'
import Input from '../../components/Input'
import useDebounce from '../../hooks/useDebounce'
import useIgnoreMount from '../../hooks/useIgnoreMount'

import { useAppContext } from '../../contexts/AppContext'

import New from './New'
import Edit from './Edit'
import List from './List'

import paths from '../../paths'

const BASE_PATH = paths.students

function Users() {
  const { t } = useTranslation()

  const { state } = useAppContext()

  const history = useHistory()

  const newMatch = useRouteMatch(`/${BASE_PATH}/${paths.common.new}`)
  const editMatch = useRouteMatch(`/${BASE_PATH}/:id/${paths.common.edit}`)

  const inputSearchRef = React.useRef(null)
  const listRef = React.useRef(null)

  const [{ q, p, o }, setQuery] = useQueryParams({ p: withDefault(NumberParam, 1), q: StringParam, o: StringParam })

  const [name, setName] = React.useState(q || '')
  const debouncedName = useDebounce(name.trim(), 500)

  useIgnoreMount(debouncedName, newDebouncedName => {
    setQuery({ p: undefined, q: newDebouncedName ? newDebouncedName : undefined })
  })

  const handleNewKey = e => {
    e.preventDefault()
    if (!window.location.href.includes(paths.common.edit)) history.push(`/${BASE_PATH}/${paths.common.new}${window.location.search}`)
  }

  const handleSearchKey = e => {
    e.preventDefault()
    inputSearchRef.current.focus()
  }

  return (
    <>
      <GlobalHotKeys keyMap={{ NEW: ['n'], SEARCH: ['f'] }} handlers={{ NEW: handleNewKey, SEARCH: handleSearchKey }} />

      <Flex flexDirection='column' width={`calc(100% - ${state.studentDetails ? '300' : '0'}px)`} css={css`transition: width 0.1s; border-right: 1px solid hsl(212deg 27% 90%);`}>
        <Flex p={3} alignItems='center' justifyContent='space-between' css={css`border-bottom: 1px solid hsl(212deg 27% 90%); background: white;`}>
          <Flex>
            <Flex mr={2}>
              <GenericIcon path='M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z' />
            </Flex>
            <Flex css={css`font-size: 24px; font-weight: 500;`}>{t('Students')}</Flex>
          </Flex>
          <Flex>
            <Input width='300px' ref={inputSearchRef} type='search' placeholder={`${t('Search')}...`} onChange={e => setName(e.target.value)} value={name} />
          </Flex>
          <Flex>
            <Tooltip tooltip={`${t('New student')} (n)`}><LinkButton to={`/${BASE_PATH}/${paths.common.new}${window.location.search}`}>{t('New student')}</LinkButton></Tooltip>
          </Flex>
        </Flex>
        <Flex flexGrow={1}>
          <List ref={listRef} search={debouncedName} page={p} orderBy={o} />
        </Flex>
      </Flex>

      <ModalRight immediate={false} shown={!!newMatch} onCancel={() => history.push(`/${BASE_PATH}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <New onRequestClose={onRequestClose} onCreate={() => { listRef.current.callRefetch() }} />
        )}
      </ModalRight>

      <ModalRight immediate={false} shown={!!editMatch} onCancel={() => history.push(`/${BASE_PATH}${window.location.search}`)}>
        {({ onRequestClose }) => (
          <Edit id={editMatch?.params?.id} onRequestClose={onRequestClose} />
        )}
      </ModalRight>
    </>
  )
}

export default Users

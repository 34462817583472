import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { Formik } from 'formik'
import { gql, useMutation } from '@apollo/client'
import * as yup from 'yup'
import { useTransition, a } from 'react-spring'

import { Flex, Box } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import Tooltip from '../../components/Tooltip'
import { useMessageContext } from '../../contexts/MessageContext'

import Form from './Form'

function New({ onRequestClose, onCreate }) {
  const { t } = useTranslation()

  const initialValues = { name: '', email: '', cellPhoneNumber: '', obs: '', password: '' }
  const validationSchema = yup.object().shape({
    name: yup.string().required(t('Required field')),
    password: yup.string().required(t('Required field'))
  })

  const { message } = useMessageContext()
  const [createStudent] = useMutation(gql`mutation ($name: String!, $email: String, $cellPhoneNumber: String, $obs: String, $password: String) { createStudent(name: $name, email: $email, cellPhoneNumber: $cellPhoneNumber, obs: $obs, password: $password) { id, name, email, cellPhoneNumber, obs } }`)

  const transition = useTransition(true, {
    from: { opacity: 0, transform: 'translate3d(50px,0px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0px,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(50px,0px,0)' }
  })

  const handleSubmit = async (variables, actions) => {
    try {
      await createStudent({ variables })
      onCreate()
      message(t('Successfully created!'))
      actions.resetForm()
    } catch(error) {
      console.error(error)
      actions.setSubmitting(false)
      const errorMessage = error.message.replace('GraphQL error: ', '')
      message(t(errorMessage))
    }
  }

  return (
    <>
      {transition((style, item) => {
        return (
          item && (
            <a.div css={css`width: 100%;`} style={{ ...style }}>
              <Flex p={1} justifyContent='flex-end'>
                <Tooltip tooltip={`${t('Close')} (esc)`} offset={8}><Box p={2} display='inline-flex' cursor='pointer' onClick={onRequestClose}><CloseIcon /></Box></Tooltip>
              </Flex>
              <Box p={4} css={css`overflow: auto; height: calc(100vh - 48px);`}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {props => <Form {...props} isCreating />}
                </Formik>
              </Box>
            </a.div>
          )
        )
      })}
    </>
  )
}

export default New

import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components/macro'
import { Field } from 'formik'

import FieldInput from '../../components/FieldInput'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import { Flex, Box } from '../../components/FlexBox'
import Checkbox from '../../components/Checkbox'



function MultiStepForm({ activeStep, values, setFieldValue, isLastStep, onBack, isSubmitting, handleSubmit, students, isCreating = false }) {
  const { t } = useTranslation()

  const selectedIds = values.students.map(u => u.id)

  const handleCheckboxChange = (id, checked) => {
    const student = students.find(item => item.id === id)
    if (checked) {
      setFieldValue('students', [...values.students, { id: student.id, name: student.name } ])
    } else {
      setFieldValue('students', [...values.students.filter(item => item.id !== student.id) ])
    }
  }

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>
      <div>
        {activeStep === 0 && (
          <Box>
            <Label required>{t('Class name')}</Label>
            <Field width='100%' name='name' autoComplete='nope' component={FieldInput} />
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Box mb={2}><Label>{t('Students')}</Label></Box>
            <Box p={2} css={css`overflow: scroll; max-height: calc(70vh - 150px); min-height: 40px; border: 1px solid ${props => (props.theme && props.theme.colors && props.theme.colors.grey200) ? props.theme.colors.grey200 : 'grey'};`}>
            {students.map(item => (
              <Flex ai='center' key={item.id}>
                <Box mr={2} mb={1}><Checkbox label={item.name} checked={selectedIds.includes(item.id)} onChange={checked => handleCheckboxChange(item.id, checked)} /></Box>
                {/* <Box>{item.name}</Box> */}
              </Flex>
            ))}
            </Box>
          </Box>
        )}
        {activeStep === 2 && (
          <Box>
            <Box mb={4}>{t('Revise as informações')}</Box>
            <Box mb={3}>
              <Label>{t(`Class name`)}:</Label>
              <Box>{values.name}</Box>
            </Box>
            <Box mb={1}><Label>{t('Students')}</Label></Box>
            <Box css={css`overflow: scroll; max-height: calc(70vh - 150px); min-height: 40px;`}>
              {values.students.map(item => (
                <Box key={item.id} mb={1}>- {item.name}</Box>
              ))}
            </Box>
          </Box>
        )}

      </div>

      <Flex alignItems='center' mt={4}>
        {activeStep !== 0 && (<Box cursor='pointer' onClick={onBack} mr={4}>{t('Back')}</Box>)}
        <Box><Button type='submit' loading={isSubmitting}>{isLastStep ? (isCreating ? t('Create') : t('Save')) : t('Next')}</Button></Box>
      </Flex>

    </form>
  )
}

export default MultiStepForm

// https://codesandbox.io/s/issue-948-puurn?file=/src/index.js:124-157
import React, { createContext, useContext, useRef } from 'react'

const MessageContext = createContext([{}, () => {}])

const MessageProvider = ({ children }) => {
  const refMessage = useRef(null)

  const message = msg => {
    refMessage.current(msg)
  }

  return (
    <MessageContext.Provider value={{ refMessage, message }}>
      {children}
    </MessageContext.Provider>
  )
}

const useMessageContext = () => useContext(MessageContext)

export { MessageContext, MessageProvider, useMessageContext }

import { useEffect } from 'react'
import { handleLogout } from '../index'
import { useApolloClient } from '@apollo/client'

function AutoLogout() {

  const client = useApolloClient()

  useEffect(() => {
    const handleChangeStorage = e => {
      if (e.key === process.env.REACT_APP_AUTH_TOKEN && e.oldValue && e.oldValue.length > 0 && !e.newValue) {
        handleLogout(client)
      }
      if (e.key === process.env.REACT_APP_AUTH_TOKEN && !e.oldValue && e.newValue && e.newValue.length > 0) {
        window.location.reload()
      }
    }
    window.addEventListener('storage', handleChangeStorage)
    return () => {
      window.removeEventListener('storage', handleChangeStorage)
    }
  }, [client])

  return null

}

export default AutoLogout

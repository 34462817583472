import React from 'react'
import styled, { css } from 'styled-components'

export const ButtonRaw = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  appearance: none;
  cursor: pointer;
  position: relative;
  user-select: none;
  outline: none;
`

const Button = ({ children, loading, ...rest}) => {
  return (
    <ButtonRaw {...rest}>
      {loading &&
        <i>
          <svg height={16} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
            <circle cx='50' cy='50' fill='none' stroke='currentColor' strokeWidth='10' r='35' strokeDasharray='164.93361431346415 56.97787143782138' transform='rotate(305.844 50 50)'>
              <animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='1s' begin='0s' repeatCount='indefinite' />
            </circle>
          </svg>
        </i>
      }
      <span style={{ display: 'inline-block', pointerEvents: 'none' }}>
        {children}
      </span>
    </ButtonRaw>
  )
}

export default styled(Button)`
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  color: white;
  background-color: ${props => props.theme && props.theme.colors && props.theme.colors.primary ? props.theme.colors.primary : 'darkgrey'};
  border-radius: 4px;
  padding: 0.6em 1em;
  ${props => props.loading && css`
    background-color: ${props => (props.theme && props.theme.colors && props.theme.colors.primary300) ? props.theme.colors.primary300 : 'hsla(203, 82%, 76%, 1)'};
    pointer-events: none;
  `}

  &:hover {
    background: ${props => props.theme?.colors?.primary600 || 'black'};
  }
  &:focus {
    outline: 3px solid ${props => props.theme?.colors?.grey200 || 'grey'};
  }
  &:active {
    background: ${props => props.theme?.colors?.primary700 || 'black'};
  }
  &:disabled {
    background-color: ${props => props.theme?.colors?.primary300 || 'grey'};
  }

  & > i {
    display: inline-block;
    line-height: 0;
    pointer-events: none;
    vertical-align: -2px;
    opacity: 0;
    margin-left: -16px;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    ${props => props.loading && css`
      opacity: 1;
      margin-left: 0px;
      margin-right: 8px;
    `}
  }
`

import React, { createContext, useState, useContext } from 'react'

const AppContext = createContext([{}, () => {}])

const AppProvider = ({ children, defaultValue = {} }) => {
  const [state, setState] = useState(defaultValue)
  const setItemState = item => setState(currentState => ({...currentState, ...item}))
  return (
    <AppContext.Provider value={{ state, setState, setItemState }}>
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = () => useContext(AppContext)

export { AppContext, AppProvider, useAppContext }

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import FieldInput from '../../components/FieldInput'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

function Form({ handleSubmit, isSubmitting }) {
  const { t } = useTranslation()

  useFocusOnLoad('email')

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label htmlFor='email'>{t('Email')}</Label>
        <Field required id='email' type='email' autoComplete='username' width='100%' name='email' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label htmlFor='password'>{t('Password')}</Label>
        <Field required id='password' type='password' autoComplete='current-password' width='100%' name='password' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Button type='submit' loading={isSubmitting}>{t('Login')}</Button>
      </Box>

    </form>
  )
}

export default Form

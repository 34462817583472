import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTransition, a, config } from 'react-spring'
import useLockBodyScroll from '../hooks/useLockBodyScroll'
import styled from 'styled-components/macro'

const ModalContent = styled(a.div)`
  position: fixed;
  z-index: 1001;
  display: flex;

  top: 0;
  right: 0;
  bottom: 0;

  width: 360px;

  @media(min-width: 768px) {
    width: 480px;
  }

  ${props => (props.$bg && `background: ${props.theme && props.theme.colors && props.theme.colors[props.$bg] ? props.theme.colors[props.$bg] : props.$bg};`)}
  ${props => (props.$boxShadow && `box-shadow: ${props.$boxShadow};`)}
`

const ModalBody = ({ children }) => {
  const mainDivRef = useRef(document.createElement('div'))
  const modalRootRef = useRef(document.getElementById('modal-root'))

  useEffect(() => {
    const modalRoot = modalRootRef.current
    if (!modalRoot) throw new Error('No modal-root exists!')
    const mainDiv = mainDivRef.current
    modalRoot.appendChild(mainDiv)
    return () => {
      modalRoot.removeChild(mainDiv)
    }
  }, [])

  return createPortal(children, mainDivRef.current)
}

const ModalRight = ({ children, onCancel, shown, immediate = false, bg = 'bodyBg', backdropColor = 'hsla(0, 0%, 0%, 0.5)', boxShadow = '0px 0px 30px hsla(0, 0%, 0%, 0.5)' }) => {
  useLockBodyScroll(shown)

  const parentDiv = useRef(null)

  useEffect(() => {
    if (shown && parentDiv.current) parentDiv.current.focus()
  }, [shown])

  const keyHandler = e => {
    if (shown && e.key === 'Escape') {
      e.preventDefault()
      e.stopPropagation()
      onCancel()
    }
  }

  const transition = useTransition(shown, {
    immediate,
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      config: item => {
        return { ...config.default, duration: 150 }
      }
    },
    leave: {
      opacity: 0,
      config: item => {
        return { ...config.default, duration: 100 }
      }
    },
  })

  if (!shown) return null // FIXME animation ??

  return transition((props, item) => {
    return (
      item && (
        <ModalBody>
          <div
            ref={parentDiv}
            onKeyDown={keyHandler}
            tabIndex={0}
            aria-modal='true'
            role='dialog'
            style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, overflow: 'hidden', outline: 'none' }}
          >
            <ModalContent $bg={bg} $boxShadow={boxShadow} style={{ opacity: props.opacity }}>
              {children({ onRequestClose: onCancel })}
            </ModalContent>
            <a.div onClick={onCancel} style={{ position: 'fixed', zIndex: 1000, top: 0, right: 0, bottom: 0, left: 0, backgroundColor: backdropColor, outline: 'none', tabIndex: -1, opacity: props.opacity }} />
          </div>
        </ModalBody>
      )
    )
  })
}

export default ModalRight

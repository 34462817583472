import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import FieldInput from '../../components/FieldInput'
import FieldTextarea from '../../components/FieldTextarea'
import FormErrorBox from '../../components/FormErrorBox'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function Form({ isCreating, handleSubmit, isSubmitting, errors, touched, values, setFieldValue }) {

  const { t } = useTranslation()

  useFocusOnLoad('name')

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>{t('Title')}</Label>
        <Field width='100%' name='title' autoComplete='nope' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label>{t('Description')}</Label>
        <Field width='100%' name='description' autoComplete='nope' component={FieldTextarea} />
      </Box>

      <Box mb={3}>
        <Button type='submit' loading={isSubmitting}>{isCreating ? t('Add') : t('Save')}</Button>
      </Box>

    </form>
  )
}

export default Form

import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTransition, a, config } from 'react-spring'
import styled from 'styled-components/macro'

const ModalContent = styled(a.div)`
  position: fixed;
  display: flex;

  top: 0;
  right: 0;
  bottom: 0;

  width: 360px;

  @media(min-width: 768px) {
    width: 300px;
  }

  ${props => (props.$bg && `background: ${props.theme && props.theme.colors && props.theme.colors[props.$bg] ? props.theme.colors[props.$bg] : props.$bg};`)}
`

const ModalBody = ({ children }) => {
  const mainDivRef = useRef(document.createElement('div'))
  const modalRootRef = useRef(document.getElementById('modal-root'))

  useEffect(() => {
    const modalRoot = modalRootRef.current
    if (!modalRoot) throw new Error('No modal-root exists!')
    const mainDiv = mainDivRef.current
    modalRoot.appendChild(mainDiv)
    return () => {
      modalRoot.removeChild(mainDiv)
    }
  }, [])

  return createPortal(children, mainDivRef.current)
}

const ModalRight = ({ children, onCancel, shown, immediate = false, bg = 'bodyBg' }) => {
  const parentDiv = useRef(null)

  useEffect(() => {
    if (shown && parentDiv.current) parentDiv.current.focus()
  }, [shown])

  const transition = useTransition(shown, {
    immediate,
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      config: item => {
        return { ...config.default, duration: 150 }
      }
    },
    leave: {
      opacity: 0,
      config: item => {
        return { ...config.default, duration: 150 }
      }
    },
  })

  if (!shown) return null // FIXME animation ??

  return transition((props, item) => {
    return (
      item && (
        <ModalBody>
          <ModalContent $bg={bg} style={{ opacity: props.opacity }}>
            {children({ onRequestClose: onCancel })}
          </ModalContent>
        </ModalBody>
      )
    )
  })
}

export default ModalRight

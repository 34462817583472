import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { Box } from '../../components/FlexBox'
import Label from '../../components/Label'
import Button from '../../components/ButtonWithLoading'
import FieldInput from '../../components/FieldInput'
import FieldTextarea from '../../components/FieldTextarea'
import FormErrorBox from '../../components/FormErrorBox'
import useFocusOnLoad from '../../hooks/useFocusOnLoad'

// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function Form({ isCreating, handleSubmit, isSubmitting, errors, touched, values, setFieldValue }) {

  const { t } = useTranslation()

  useFocusOnLoad('name')

  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={3}>
        <Label required>{t('Name')}</Label>
        <Field width='100%' name='name' autoComplete='nope' component={FieldInput} />
      </Box>

      <Box mb={3}>
        <Label>{t('Email')}</Label>
        <Field width='100%' type='email' name='email' autoComplete='nope' component={FieldInput} />
      </Box>

      {isCreating && (
        <Box mb={3}>
          <Label>{t('Password')}</Label>
          <Field width='100%' type='password' name='password' autoComplete='new-password' component={FieldInput} />
        </Box>
      )}

      <Box mb={3}>
        <Label>{t('Cell Phone Number')}</Label>
        <PhoneInput onChange={value => setFieldValue('cellPhoneNumber', (value || ''))} value={values.cellPhoneNumber} />
        <FormErrorBox fieldName='cellPhoneNumber' errors={errors} touched={touched} />
      </Box>

      <Box mb={3}>
        <Label>{t('Comments')}</Label>
        <Field width='100%' name='obs' autoComplete='nope' component={FieldTextarea} />
      </Box>

      <Box mb={3}>
        <Button type='submit' loading={isSubmitting}>{isCreating ? t('Add') : t('Save')}</Button>
      </Box>

    </form>
  )
}

export default Form

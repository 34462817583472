import { desaturate, lighten, darken, invert } from 'polished'

const tailwindColors = {
  royalblue: {
    '50':  '#f6fbfd',
    '100': '#e7f8fd',
    '200': '#c2e8fb',
    '300': '#98d2fb',
    '400': '#5fa7fa',
    '500': '#2f7af9',
    '600': '#0963ef',
    '700': '#1b43dd',
    '800': '#1733aa',
    '900': '#132a82',
  },
  indigo: {
    '50':  '#f5f9fc',
    '100': '#e8f2fc',
    '200': '#cddcfb',
    '300': '#afc1fa',
    '400': '#8994fa',
    '500': '#6066fa',
    '600': '#4645f6',
    '700': '#3936e3',
    '800': '#2d2cb8',
    '900': '#242591',
  },
  flamingo: {
    '50':  '#f6f5fa',
    '100': '#f0e7fa',
    '200': '#e3c9f8',
    '300': '#d6a8f7',
    '400': '#ce7af7',
    '500': '#c54ff7',
    '600': '#ab32f1',
    '700': '#8428dd',
    '800': '#6522b3',
    '900': '#511e8e',
  },
  cerise: {
    '50':  '#fcf9f9',
    '100': '#fdeef5',
    '200': '#fbceea',
    '300': '#faa4da',
    '400': '#fb6abd',
    '500': '#fc409c',
    '600': '#f62575',
    '700': '#db1d5b',
    '800': '#ae1844',
    '900': '#891535',
  },
  scarlet: {
    '50':  '#fcf8f6',
    '100': '#fcefee',
    '200': '#fad5db',
    '300': '#f9b1ba',
    '400': '#fa7c86',
    '500': '#fa5158',
    '600': '#f3323a',
    '700': '#d52634',
    '800': '#aa1f2e',
    '900': '#861a27',
  },
  mango: {
    '50':  '#faf6ee',
    '100': '#fbeed7',
    '200': '#f8deab',
    '300': '#f6c56c',
    '400': '#f39e30',
    '500': '#f27615',
    '600': '#e6520e',
    '700': '#c53d12',
    '800': '#9f3118',
    '900': '#812818',
  },
  orange: {
    '50':  '#faf7ed',
    '100': '#faf3d0',
    '200': '#f6e895',
    '300': '#f1d54f',
    '400': '#e7b51b',
    '500': '#df930a',
    '600': '#c96e06',
    '700': '#a2520a',
    '800': '#7f4010',
    '900': '#643311',
  },
  sunglow: {
    '50':  '#fafaf2',
    '100': '#faf7d8',
    '200': '#f4ef9a',
    '300': '#ebdf53',
    '400': '#d8c31b',
    '500': '#bfa309',
    '600': '#9b7f05',
    '700': '#766108',
    '800': '#58490d',
    '900': '#44390e',
  },
  limegreen: {
    '50':  '#f3f9f7',
    '100': '#e6f8ef',
    '200': '#c6f2d6',
    '300': '#95e7b6',
    '400': '#44d382',
    '500': '#1aba51',
    '600': '#149d38',
    '700': '#197e33',
    '800': '#1a602f',
    '900': '#174d2a',
  },
  cerulean: {
    '50':  '#f0f9fb',
    '100': '#daf7f8',
    '200': '#adeef1',
    '300': '#75e1eb',
    '400': '#30c8e2',
    '500': '#11a8d5',
    '600': '#0d87be',
    '700': '#126c99',
    '800': '#135371',
    '900': '#124358',
  },
}

const lightColors = tailwindColors['scarlet']
const darkColors = tailwindColors['flamingo']


const themes = {
  default: {
    baseFontSize: '14px',
    baseFontWeight: 400,
    baseLineHeight: '1',
    bodyFontFamily: 'Inter',
    displayFontFamily: 'Sen',
    displayFontWeight: 700,
    colors: {
      body: 'hsl(212deg 33% 18%)',
      bodyBg: '#FFFFFF',
      navBg: desaturate(0.3, lightColors['600']),
      white: 'white',
      black: 'black',
      grey50: desaturate(0.5, lightColors['50']),
      grey100: desaturate(0.5, lightColors['100']),
      grey200: desaturate(0.5, lightColors['200']),
      grey300: desaturate(0.5, lightColors['300']),
      grey400: desaturate(0.5, lightColors['400']),
      grey: desaturate(0.5, lightColors['500']),
      primary50: lightColors['50'],
      primary100: lightColors['100'],
      primary200: lightColors['200'],
      primary300: lightColors['300'],
      primary400: lightColors['400'],
      primary: lightColors['500'],
      primary600: lightColors['600'],
      primary700: lightColors['700'],
      error: 'hsla(6, 66%, 47%, 1)'
    }
  },
  dark: {
    baseFontSize: '13px',
    baseFontWeight: 400,
    baseLineHeight: '1',
    bodyFontFamily: 'Inter',
    displayFontFamily: 'Sen',
    colors: {
      body: 'white',
      bodyBg: 'black',
      navBg: '#DD4540',
      white: lighten(0.05, 'black'),
      black: darken(0.05, 'white'),
      grey50: invert(desaturate(0.5, darkColors['50'])),
      grey100: invert(desaturate(0.5, darkColors['100'])),
      grey200: invert(desaturate(0.5, darkColors['200'])),
      grey300: invert(desaturate(0.5, darkColors['300'])),
      grey400: invert(desaturate(0.5, darkColors['400'])),
      grey: invert(desaturate(0.5, darkColors['500'])),
      primary50: darkColors['50'],
      primary100: darkColors['100'],
      primary200: darkColors['200'],
      primary300: darkColors['300'],
      primary400: darkColors['400'],
      primary: darkColors['500'],
      primary600: darkColors['600'],
      error: 'hsla(6, 66%, 47%, 1)'
    }
  },
}

export default themes

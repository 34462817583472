import React from 'react'
import ReactDOM from 'react-dom'

import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext }        from 'apollo-link-context'
import { onError }           from 'apollo-link-error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'

const BASE_API = process.env.REACT_APP_BASE_API
const WS_BASE_API = BASE_API.replace('http', 'ws')

export const handleLogout = client => {
  localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)
  if (client) client.resetStore()
  window.location.href = '/login'
}

const httpLink = new HttpLink({ uri: `${BASE_API}/graphql` })
const wsLink = new WebSocketLink({
  uri: `${WS_BASE_API}/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Context creation failed: Your session expired. Sign in again.') {
        handleLogout()
      }
      if (message === 'Not authenticated as user.') {
        handleLogout()
      }
    })
  }
  if (networkError && networkError.statusCode === 401) {
    console.log(`[Network error]: ${networkError}`)
    handleLogout(client)
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
  return { headers: {...headers, authorization: token ? `Bearer ${token}` : ''} }
})

const isSubscriptionOperation = ({ query }) => {
  const { kind, operation } = getMainDefinition(query)
  return kind === 'OperationDefinition' && operation === 'subscription';
}
const requestLink = split(isSubscriptionOperation, wsLink, httpLink)

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, requestLink]),
  cache: new InMemoryCache(),
  defaultOptions: {}
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
